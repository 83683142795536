jQuery('button.menu-bar-toggle').unbind('click').click(function() {
  jQuery('.menu-bar').toggleClass('collapsed');
});

jQuery('.close-btn').unbind('click').click(function() {
  jQuery('.menu-bar').toggleClass('collapsed');
});


// var stickyNav = jQuery('.nav-wrapper').offset().top;
//
// jQuery(window).scroll(function() {
//   if (jQuery(window).scrollTop() > stickyNav) {
//     jQuery('body.home .nav-wrapper').addClass('affix');
//   } else {
//     jQuery('body.home .nav-wrapper').removeClass('affix');
//   }
// });
